export default {
	website: {
		title: '阿里企业邮箱',
		desc: '阿里云作为业内唯一基于云计算原生公有云服务的邮件运营商，自主研发阿里邮箱。阿里邮箱拥有与支付宝同等级的安全攻防体系，每用户不限容量，多终端支持，钉+邮随时随地轻松办公，支持企业邮箱本地部署，符合全栈国产化要求，同时售后7*24小时VIP服务支持，保障数据迁移无缝对接。',
		head_btn: '立即购买',
		tip: '高效、安全的企业邮箱解决方案，为企业提供专业、稳定的邮箱服务，助力企业沟通无忧，提升工作效率。',
		spec_title: '企业邮箱产品规格',
		spec_desc: '阿里企业邮箱不限容量，定制企业专属形象，助力千万企业增加客户信任度',
		version1: '标准版',
		version2: '高级版',
		version3: '企业版',
		version4: '旗舰版',
		capacity: '容量：不限制',
		account: '账号数：{num}人',
		product_feature: '产品特点',
		feature1_title: '超大存储空间',
		feature1_desc: '支持海量邮件存储，无需频繁清理邮箱',
		feature2_title: '移动办公支持',
		feature2_desc: '支持多设备同步，随时随地处理邮件',
		feature3_title: '智能过滤',
		feature3_desc: '智能识别垃圾邮件，提升邮箱使用体验',
		feature4_title: '邮件追踪',
		feature4_desc: '实时追踪邮件状态，确保重要邮件不被遗漏。',
		odds: '优势',
		odds_desc: '稳定性高，收发速度快',
		odds1_title: '邮件沟通与合作',
		odds1_desc:
			'提供高效、稳定的邮件通讯工具，促进企业内部以及与合作伙伴之间的沟通合作，保证业务运营的顺畅。',
		odds2_title: '客户联络与服务',
		odds2_desc: '通过企业邮箱，可以与客户保持紧密联系，及时回复客户咨询，提供优质的客户服务。',
		odds3_title: '跨境业务拓展',
		odds3_desc: '支持多语言，满足跨境业务的邮件沟通需求，帮助企业扩大国际市场。',
		odds4_title: '团队协作',
		odds4_desc: '作为团队内部协作的重要工具，方便团队成员之间进行信息共享和沟通。',
		company_email_used: '企业邮箱使用场景',
		used1_title: '多终端支持',
		used1_desc: '专属移动客户端，全面支持IOS、ANDROID手机随时随地、轻松办公',
		used2_title: '异常登录提醒',
		used2_desc: '邮箱异常登录立刻提醒，为您的邮箱安全保驾护航',
		used3_title: '多任务模式',
		used3_desc: '可同时多窗口进行读写邮件、设置邮箱等操作企业办公更高效',
		used_item1_title: '钉钉收发信',
		used_item1_desc: '关联钉钉与邮箱账号，就能像聊天一样使用钉钉收发邮件',
		used_item2_title: "{'@'}收件人",
		used_item2_desc: "可以在邮件的正文直接{'@'}某人，{'@'}智能匹配并自动将选定地址添加至收件人",
		used_item3_title: '网盘',
		used_item3_desc: '文件网络备份、同步和分享服务，包括个人网盘和企业共享网盘',
		used_item4_title: '自助查询日志',
		used_item4_desc: '用户可方便的自助查询登录、收发、删除等日志信息',
		used_item5_title: '全文检索',
		used_item5_desc: '云端智能全文检索，让您快速查询到所需邮件和联系人等重要信息',
		used_item6_title: '会话模式',
		used_item6_desc: '按主题将邮件分类的浏览模式，在同一个会话里展示相关邮件的来往情况',
		used_item7_title: '日程管理',
		used_item7_desc:
			'全面管理工作行程安排与会议邀约，可查看公司成员忙闲状态，可将日历共享给他人，节省沟通成本',
		used_item8_title: '邮件撤回',
		used_item8_desc: '让误发送的邮件便捷的收回，仅支持撤回发往阿里邮箱系统、且对方尚未阅读的邮件',
		used_item9_title: '疑似垃圾邮件提醒',
		used_item9_desc:
			'对于被拦截的垃圾邮件，系统每天进行提醒，以免错过重要的邮件，可便捷的根据需要进行开启和关闭',
		email_fun: '邮箱后台管理功能',
		email_fun_desc: '为什么选择阿里邮箱？我们能为您带来这些',
		email_fun1_title: '邮件监控',
		email_fun1_desc: '可设定监控账号与被监控账号，满足企业对邮箱安全管控需求',
		email_fun2_title: '分级管理员',
		email_fun2_desc: '设定不同级别、不同权限的管理员，提升管理效率',
		email_fun3_title: '企业回收站',
		email_fun3_desc: '误删邮件轻松找回，邮件管理更安全、更放心',
		email_fun_item1_title: '邮箱归档',
		email_fun_item1_desc: '长期保存企业来往邮件，帮助企业快速检索邮件',
		email_fun_item2_title: '邮箱搬家',
		email_fun_item2_desc: '轻松将原服务商企业邮箱的邮件迁移到新的企业邮箱',
		email_fun_item3_title: '成员外发限制',
		email_fun_item3_desc: '可限制域内成员向外域发送邮件',
		email_fun_item4_title: '多域管理',
		email_fun_item4_desc:
			'单组织邮箱帐号数10个以上支持绑定多个域名，根据组织管理需求，可实现不同域名创建账号或统一设定',
		email_fun_item5_title: '组织通讯录设置',
		email_fun_item5_desc:
			'设定成员和部门的可见性，设定成员和部门的显示排序；设定成员多部门兼职显示',
		email_fun_item6_title: '企业形象定制',
		email_fun_item6_desc: '定制邮箱企业形象，包括邮箱LOGO、邮箱登录页、统一签名等',
		carousel1: '通过使用我们的企业邮箱服务，实现了全球团队的顺畅沟通，邮件处理效率提升30%。',
		carousel2: '借助我们的邮箱解决方案，确保了师生之间的高效沟通，同时保障了数据安全。',
		carousel3: '通过定制化邮箱服务，优化了供应链沟通流程，业务响应速度显著提升。',
		consult: '请联系建站顾问，了解详情',
		websiteEmail: '企业邮箱',
		go_buy: '前往购买',
		version: '版本',
		people_num: '人数',
		duration: '时长',
		people: '人',
		created_start_at: '请选择创建开始时间',
		created_end_at: '请选择创建结束时间',
		product_sku: '产品规格',
		product_trait: '产品特点',
		product_odd: '产品优势',
		use_scen: '使用场景',
		my_buyed: '我的购买',
		email_domain: '邮箱域名',
		pl_email_domain: '请输入邮箱域名',
		start_buy: '立即购买',
		hot: '最受欢迎',
		start_year: '/1年起',
		buy_time: '购买时长',
		valid_domain: '请输入正确的域名格式',
	},
}
