// 定义内容
export default {
	lang: {
		router: {
			homeInfo: '店铺信息',
			home: '活跃店铺',
			homeCancel: '注销店铺',
			billManage: '账单管理',
			sonuserManage: '子账号管理',
			wallet: '钱包余额',
			invoiceManage: '发票管理',
			domainManage: '域名管理',
			statistics: '数据统计',
			storeStatistics: '店铺统计',
			accountStatistics: '子账号统计',
			messages: '消息中心',
			commentFeedback: '评论反馈',
			businessDetail: '经营明细',
			orders: '我的订单',
			domainPanel: '控制面板',
			domainReg: '注册域名',
			dns: '域名解析',
			applyInvoice: '申请开票',
			invoiceDetail: '查看详情',
			balanceRecharge: '余额充值',
			motivationSharing: '激励分享',
			dashboard: '概览',
			billRecord: '账单记录',
			withdraw: '提现管理',
			smp: '供销平台',
			dashboardHome: '概览',
			storesAuth: '授权店铺',
			panel: '数据面板',
			dedicatedIp: '网站独立IP',
			dedicatedIpBuy: '购买IP',
			billDetails: '账单明细',
			billSummary: '店铺汇总',
			website: '建站必备',
			websiteCompany: '企业邮箱',
			websiteServer: '云服务器',
		},
		staticRoutes: {
			signIn: '登录',
			notFound: '找不到此页面',
			noPower: '没有权限',
			terms: '条款',
			underReview: '审核中',
			payout: '快捷支付扣款授权确认书',
			suspend: '系统错误',
		},
		user: {
			home: '店铺首页', //店铺首页
			personal: '个人中心', //个人中心
			help: '帮助中心', //帮助中心
			logOut: '退出登录', //退出登录
			counselor: '建站顾问',
			server: '在线客服',
			contact: '有任何问题或需求，请联系我们。 工作时间: 9:00-18:00',
			api: 'API文档',
			evaluate: '评论反馈',
			title0: '组件大小',
			title1: '语言切换',
			msg: '消息中心',
			not_msg: '没有消息通知！',
			title2: '菜单搜索',
			title3: '布局配置',
			title4: '消息',
			title5: '开全屏',
			title6: '关全屏',
			dropdownDefault: '默认',
			dropdownMedium: '中等',
			dropdownSmall: '小型',
			dropdownMini: '超小',
			dropdown3: '404',
			dropdown4: '401',
			dropdown5: '退出登录',
			dropdown6: '代码仓库',
			searchPlaceholder: '菜单搜索：支持中文、路由路径',
			newTitle: '通知',
			newBtn: '全部已读',
			newGo: '前往通知中心',
			newDesc: '暂无通知',
			logOutTitle: '提示',
			logOutMessage: '此操作将退出登录, 是否继续?',
			logOutConfirm: '确定',
			logOutCancel: '取消',
			logOutExit: '退出中',
			logOutSuccess: '安全退出成功！',
			guide: '开店向导',
		},
		tagsView: {
			refresh: '刷新',
			close: '关闭',
			closeOther: '关闭其它',
			closeAll: '全部关闭',
			fullscreen: '当前页全屏',
		},
		notFound: {
			foundTitle: '地址输入错误，请重新输入地址~',
			foundMsg: '您可以先检查网址，然后重新输入或给我们反馈问题。',
			foundBtn: '返回首页',
		},
	},
}
