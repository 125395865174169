/**
 * 获取url参数
 * @param {String} variable 参数名
 * @returns 对应的url参数
 */

import { computed } from 'vue'
import { store, useStore } from '../store'
import { Session, Cookies } from './storage'
import { resetRoute } from '../router'

export function getUrlParams(variable: string) {
	const query = window.location.search.substring(1)
	const vars = query.split('&')
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=')
		if (pair[0] == variable) {
			return decodeURIComponent(pair[1])
		}
	}
	return ''
}

/**
 *
 * @param {String} paramKey 参数名
 * @returns 被处理过的url
 */
export const delParam = (paramKey: string) => {
	let url = window.location.href //页面url
	const urlParam = window.location.search.substr(1) //页面参数
	const beforeUrl = url.substr(0, url.indexOf('?')) //页面主地址（参数之前地址）
	let nextUrl = ''

	const arr: string[] = []
	if (urlParam != '') {
		const urlParamArr = urlParam.split('&') //将参数按照&符分成数组
		for (let i = 0; i < urlParamArr.length; i++) {
			const paramArr = urlParamArr[i].split('=') //将参数键，值拆开
			//如果键雨要删除的不一致，则加入到参数中
			if (paramArr[0] != paramKey) {
				arr.push(urlParamArr[i])
			}
		}
	}
	if (arr.length > 0) {
		nextUrl = '?' + arr.join('&')
	}
	url = beforeUrl + nextUrl
	return url
}
/**
 * 判断当前文件是否能被压缩
 * @param src
 * @returns boolean
 */
export const getClipFlag = (src?: string): boolean => {
	if (!src) return false

	const type = getFileType(src)
	const clip_src = ['.jpeg', '.jpg', '.png']
	if (clip_src.includes(type)) return true
	return false
}
/**
 * 修改url参数
 * @param {String} name 参数名
 * @param {String} value 参数值
 */
export const changeURLParams = (name: string, value: string) => {
	const url = location.href
	const reg = eval('/([?|&]' + name + '=)[^&]*/gi')
	value = value.toString().replace(/(^\s*)|(\s*$)/g, '') //移除首尾空格
	let url2
	if (!value) {
		url2 = url.replace(reg, '') //正则替换
	} else {
		if (url.match(reg)) {
			url2 = url.replace(reg, '$1' + value) //正则替换
		} else {
			url2 = url + (url.indexOf('?') > -1 ? '&' : '?') + name + '=' + value //没有参数添加参数
		}
	}
	history.replaceState(null, '', url2) //替换地址栏
}
/**
 * 判断是否为纯对象
 * @param {*} tar
 * @returns 是否为 纯对象
 */
export const isPlainObjct = (tar: any) => {
	return Object.prototype.toString.call(tar) === '[object Object]'
}

/**
 * 深拷贝
 * @param source 拷贝的数据
 * @returns
 */
export const cloneDeep = function <T>(source: T): T {
	let sourceCopy: T = source instanceof Array ? ([] as unknown as T) : ({} as T)
	for (let item in source) {
		sourceCopy[item] =
			typeof source[item] === 'object' &&
			Object.prototype.toString.call(source[item]) !== '[object Null]'
				? cloneDeep(source[item])
				: source[item]
	}
	return sourceCopy
}

// 打开外部链接
export const openUrl = (link: string, target: '_self' | '_blank' = '_blank') => {
	const a = document.createElement('a')
	const url = decodeURI(link)
	a.href = url
	a.target = target
	setTimeout(() => {
		a.click()
	})
}
/**
 * 判断两个表单数据是否相同
 * @param objA
 * @param objB
 * @returns 是否相同
 */
export const compareJSON = (
	objA: object,
	objB: object,
	includes?: string[],
	excludes?: string[]
): boolean => {
	const typeA = getType(objA)
	const typeB = getType(objB)

	// 任意一个数据不为 纯对象或数组 ，都返回false
	if (typeA !== 'Object' && typeA !== 'Array') {
		return false
	}
	// 如果两个数据不是同种类型，则直接返回 false
	if (typeA !== typeB) return false

	delEmpty(objA)
	delEmpty(objB)

	const assignObj = { ...objA, ...objB }
	let compareKeys = Object.keys(assignObj)
	// 查找需要比较的字段
	if (includes) {
		const arr = includes.filter((str) => {
			return /^((?!\.).)*$/.test(str) // 返回所有不包含 "." 的字符串
		})

		if (arr.length) {
			compareKeys = []
			arr.forEach((str) => {
				compareKeys = compareKeys.concat(str.split(','))
			})
			includes = undefined
		}
	}

	// 排除key
	if (excludes) {
		const arr = excludes.filter((str) => {
			return /^((?!\.).)*$/.test(str) // 返回所有不包含 "." 的字符串
		})

		if (arr.length) {
			arr.forEach((str) => {
				const index = compareKeys.findIndex((key) => key === str)
				if (index !== -1) compareKeys.splice(index, 1)
			})
			excludes = undefined
		}
	}

	for (const key of compareKeys) {
		let args: undefined | string[] = undefined
		let excludeArgs: undefined | string[] = undefined

		// 设置 args
		if (includes) {
			const arr = includes.filter((item) => {
				const text = item.split('.')[0]
				return text === key || text === '*'
			})

			if (arr.length) {
				args = arr.map((str) => str.split('.').splice(1).join('.'))
			}
		}

		// 需要排除的key
		if (excludes) {
			const arr = excludes.filter((item) => {
				const text = item.split('.')[0]
				return text === key || text === '*'
			})

			if (arr.length) {
				excludeArgs = arr.map((str) => str.split('.').splice(1).join('.'))
			}
		}

		let targetA = objA[key]
		let targetB = objB[key]

		if (typeof targetA === 'number' && typeof targetB === 'string') {
			return targetA.toString() === targetB
		}

		if (targetA !== targetB) {
			if (!compareJSON(targetA, targetB, args, excludeArgs)) return false
		}
	}

	return true
}

/**
 * 获取数据类型
 * @param target
 * @returns 数据类型
 */
export const getType = (target): string => {
	const str = Object.prototype.toString.call(target).match(/ \w+/) as RegExpMatchArray
	return str[0].trim()
}

/** 删除对象中值为 空字符串、undefined、null 的字段 */
function delEmpty(target): void {
	const keys = Object.keys(target)

	// 去除空数据
	for (const key of keys) {
		const value = target[key]

		if (value === '' || value == undefined) {
			delete target[key]
		}
	}
}

/**
 * 获取文件后缀名
 * @param file 要转换的数据string
 * @returns 返回s数据
 */
export function getFileType(file: string) {
	let filename = file
	let index1 = filename.lastIndexOf('.')
	let index2 = filename.length
	let type = filename.substring(index1, index2)
	return type
}

/**
 * 对象转url参数
 * @param {*} data,对象
 * @param {*} isPrefix,是否自动加上"?"
 */
export const queryParams = (data = {}, isPrefix = true, arrayFormat = 'comma') => {
	let prefix = isPrefix ? '?' : ''
	let _result: string[] = []
	if (['indices', 'brackets', 'repeat', 'comma'].indexOf(arrayFormat) == -1)
		arrayFormat = 'brackets'
	for (let key in data) {
		let value = data[key]
		// 去掉为空的参数
		if (['', undefined, null].indexOf(value) >= 0) {
			continue
		}
		// 如果值为数组，另行处理
		if (value.constructor === Array) {
			// e.g. {ids: [1, 2, 3]}
			switch (arrayFormat) {
				case 'indices':
					// 结果: ids[0]=1&ids[1]=2&ids[2]=3
					for (let i = 0; i < value.length; i++) {
						_result.push(key + '[' + i + ']=' + value[i])
					}
					break
				case 'brackets':
					// 结果: ids[]=1&ids[]=2&ids[]=3
					value.forEach((_value) => {
						_result.push(key + '[]=' + _value)
					})
					break
				case 'repeat':
					// 结果: ids=1&ids=2&ids=3
					value.forEach((_value) => {
						_result.push(key + '=' + _value)
					})
					break
				case 'comma':
					// 结果: ids=1,2,3
					let commaStr = ''
					value.forEach((_value) => {
						commaStr += (commaStr ? ',' : '') + _value
					})
					_result.push(key + '=' + commaStr)
					break
				default:
					value.forEach((_value) => {
						_result.push(key + '[]=' + _value)
					})
			}
		} else {
			_result.push(key + '=' + value)
		}
	}
	return _result.length ? prefix + _result.join('&') : ''
}

/* 每种屏幕的最大尺寸 */
export const screenSize = {
	SMALL_MOBILE: 460,
	MOBILE: 767,
	SMALL_IPAD: 846,
	IPAD: 1024,
}

export const getScreenType = () => {
	const { SMALL_MOBILE, MOBILE, SMALL_IPAD, IPAD } = screenSize

	const IS_SMALL_MOBILE = window.innerWidth <= SMALL_MOBILE
	const IS_MOBILE = window.innerWidth <= MOBILE
	const IS_SMALL_IPAD = window.innerWidth > MOBILE && window.innerWidth <= SMALL_IPAD
	const IS_IPAD = window.innerWidth > MOBILE && window.innerWidth <= IPAD
	const IS_PC = window.innerWidth > IPAD

	return {
		IS_SMALL_MOBILE,
		IS_MOBILE,
		IS_SMALL_IPAD,
		IS_IPAD,
		IS_PC,

		MOBILE_OR_SMALL_IPAD: IS_MOBILE || IS_SMALL_IPAD,
		MOBILE_OR_IPAD: IS_MOBILE || IS_IPAD,
		IPAD_OR_PC: IS_IPAD || IS_PC,
	}
}

export const getCompare = (num1: string | number, num2: string | number) => {
	const strNum1 = num1.toString()
	const strNum2 = num2.toString()
	if (strNum1.includes('%') || strNum2.includes('%')) {
		const value =
			(Number(strNum1.replace(/\%/g, '')) * 100 - Number(strNum2.replace(/\%/g, '')) * 100) / 100
		const className = !value ? 'default' : value < 0 ? 'down' : 'top'
		const newVal = value.toFixed(2).includes('.00') ? value + '%' : value.toFixed(2) + '%'

		return {
			value: !value ? '-' : newVal,
			className,
		}
	}

	const newNum1 = Number(strNum1.replace(/\,/g, ''))
	const newNum2 = Number(strNum2.replace(/\,/g, ''))
	let compare = 0
	if (newNum2) compare = ((newNum1 - newNum2) * 100) / newNum2
	const value = compare ? compare.toFixed(2) + '%' : '-'
	const className = value === '-' ? 'default' : value.includes('-') ? 'down' : 'top'

	return {
		value: value.includes('.00') ? value.replace('.00', '') : value,
		className,
	}
}

export const isHW = () => {
	const brandIds = [120, 197, 209]
	return brandIds.includes(store.state.userInfos.brand_id || store.state.platForm.id)
}

/**
 * 格式化手机号中间几位显示****
 * @param {*} phoneNumber,需要格式的手机号
 */
export const maskPhoneNumber = (phoneNumber: string) => {
	// 如果输入不是字符串或者长度小于11，直接返回空字符串
	if (typeof phoneNumber !== 'string' || phoneNumber.length < 11) {
		return ''
	}

	// 使用正则表达式将中间的数字替换为星号
	return phoneNumber.replace(/(\d{3})\d+(\d{3})/, '$1****$2')
}

export const showStatistics = computed(() => {
	const { state } = useStore()
	const ext_params = state.platForm?.ext_params
	return !(ext_params?.statistics_hide ? Number(ext_params?.statistics_hide) : 0)
})

export const resetStateAndLogout = () => {
	Cookies.remove()
	Session.clear()
	resetRoute()
}

export const getQueryParams = (query: any, code = 'invite_code') => {
	const res = query?.invite_code
		? query.invite_code
		: query.params
		? JSON.parse(query.params)?.[code]
		: ''
	return res || ''
}
